export function newProspex() {
    return {
        nome: '',
        idade: null,
        cpf: '',
        responsavel: '',
        codigo_municipio_completo: null,
        bairro: '',
        email: '',
        fone_fixo: '',
        celular: '',
        operadora: '',
        estuda: null,
        escola: '',
        curso: '',
        trabalha: null,
        empresa: '',
        setor: '',
        cursou_ingles: null,
        cursou_espanhol: null,
        cursou_frances: null,
        cursou_alemao: null,
        cursou_italiano: null,
        cursou_idioma_escola: '',
        cursou_idioma_duracao: '',
        como_conheceu_knn: '',
        curso_interesse: [],
        dias_preferencia: [],
        periodo_preferencia: [],
        data_atendimento: null,
        created_by_id: null,
        demonstrativa: '',
        observacoes: '',
        status_id: 1,
        atendente_id: null,
        parceria_cupom_id: null,
        parceria_cupom: null,
        indicou_aluno_id: null,
        indicou_aluno_nome: null,
    };
}
