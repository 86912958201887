import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import { entidadeStore } from '@/store';
let SearchEntidadeListComponent = class SearchEntidadeListComponent extends Vue {
    constructor() {
        super(...arguments);
        this.loadingEntidades = false;
        this.dialogList = false;
        this.entidadeHeader = [
            {
                text: 'Nome',
                value: 'nome',
            },
            {
                text: 'Tipo',
                value: 'tipo',
            },
        ];
        this.search = '';
        // @ts-ignore
        this.entidadeItems = [];
        this.selectedListItem = [];
        this.selectSearchEntidadeItem = null;
    }
    watchSelectSearchEntidadeItem() {
        if (!this.selectSearchEntidadeItem) {
            this.$emit('input', null);
            return;
        }
        this.$emit('input', this.selectSearchEntidadeItem);
    }
    watchSelectedListItem() {
        this.dialogList = false;
        if (this.selectedListItem.length <= 0) {
            this.$refs.SearchEntidadeComponent.addOption(null);
            this.$emit('input', null);
            return;
        }
        const selected = this.selectedListItem[0];
        const entidadeObj = {
            tipo: selected.tipo,
            id: selected.id,
            nome: selected.nome,
        };
        this.$refs.SearchEntidadeComponent.addOption(entidadeObj);
        this.selectSearchEntidadeItem = entidadeObj;
        this.$emit('input', entidadeObj);
    }
    async openEntidadeList() {
        this.dialogList = true;
        this.loadingEntidades = true;
        const res = await entidadeStore.getEntidadesList({
            types: this.propEntityType,
            departamento_id: this.departamento_id,
            show_inactive: this.propShowInactive,
        });
        this.entidadeHeader = res.headers;
        this.entidadeItems = res.items;
        this.loadingEntidades = false;
    }
    mounted() {
        if (this.value && this.value.id > 0 && Object.keys(this.value).length > 0) {
            this.$refs.SearchEntidadeComponent.addOption(this.value);
        }
    }
};
__decorate([
    Prop()
], SearchEntidadeListComponent.prototype, "value", void 0);
__decorate([
    Prop({ default: 'Entidade' })
], SearchEntidadeListComponent.prototype, "propTitle", void 0);
__decorate([
    Prop()
], SearchEntidadeListComponent.prototype, "departamento_id", void 0);
__decorate([
    Prop({ default: false })
], SearchEntidadeListComponent.prototype, "propDisabled", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], SearchEntidadeListComponent.prototype, "propReadonly", void 0);
__decorate([
    Prop({ default: false })
], SearchEntidadeListComponent.prototype, "propDense", void 0);
__decorate([
    Prop({ default: false })
], SearchEntidadeListComponent.prototype, "propDisableValidator", void 0);
__decorate([
    Prop({ default: '' })
], SearchEntidadeListComponent.prototype, "propCustomErrorMessage", void 0);
__decorate([
    Prop({ default: true })
], SearchEntidadeListComponent.prototype, "propShowInactive", void 0);
__decorate([
    Prop({ default: false })
], SearchEntidadeListComponent.prototype, "propOutlined", void 0);
__decorate([
    Prop({ default: false })
], SearchEntidadeListComponent.prototype, "propRounded", void 0);
__decorate([
    Prop({ default: [] })
], SearchEntidadeListComponent.prototype, "propEntityType", void 0);
__decorate([
    Prop({ default: () => [] })
], SearchEntidadeListComponent.prototype, "propEntityFields", void 0);
__decorate([
    Prop({ default: null })
], SearchEntidadeListComponent.prototype, "propDefaultEntityField", void 0);
__decorate([
    Watch('selectSearchEntidadeItem')
], SearchEntidadeListComponent.prototype, "watchSelectSearchEntidadeItem", null);
__decorate([
    Watch('selectedListItem')
], SearchEntidadeListComponent.prototype, "watchSelectedListItem", null);
SearchEntidadeListComponent = __decorate([
    Component({
        components: {
            SearchEntidadeComponent,
        },
    })
], SearchEntidadeListComponent);
export default SearchEntidadeListComponent;
